import request from '@/utils/request'


// 查询港口信息列表
export function listPort(query) {
  return request({
    url: '/gather/gather-port/list',
    method: 'get',
    params: query
  })
}

// 查询港口信息分页
export function pagePort(query) {
  return request({
    url: '/gather/gather-port/page',
    method: 'get',
    params: query
  })
}

// 查询港口信息详细
export function getPort(data) {
  return request({
    url: '/gather/gather-port/detail',
    method: 'get',
    params: data
  })
}

// 新增港口信息
export function addPort(data) {
  return request({
    url: '/gather/gather-port/add',
    method: 'post',
    data: data
  })
}

// 修改港口信息
export function updatePort(data) {
  return request({
    url: '/gather/gather-port/edit',
    method: 'post',
    data: data
  })
}

// 删除港口信息
export function delPort(data) {
  return request({
    url: '/gather/gather-port/delete',
    method: 'post',
    data: data
  })
}
