<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('港口名称')}}<template slot="title">{{$t('港口名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('港口名称')" />
      </a-form-model-item>
      <a-form-model-item prop="userName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('所属用户')}}<template slot="title">{{$t('所属用户')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('所属用户')" />
      </a-form-model-item>
      <a-form-model-item prop="userName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('所属用户')}}<template slot="title">{{$t('所属用户')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('所属用户')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="picture" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('港口图片')}}<template slot="title">{{$t('港口图片')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.picture" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('港口图片')" />-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop="portType">
        <span slot="label" >
          <a-tooltip>
            {{$t('物标')}}<template slot="title">{{$t('物标')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.portType"  :placeholder="$t('通用.输入.请输入')+$t('物标')" />
      </a-form-model-item>
      <a-form-model-item prop="positioningType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('定位类型')}}<template slot="title">{{$t('定位类型')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.positioningType"  :placeholder="$t('通用.输入.请输入')+$t('定位类型')" />
      </a-form-model-item>
      <a-form-model-item prop="monitorIdList" >
        <span slot="label" >
          <a-tooltip>
            {{$t('绑定监控')}}<template slot="title">{{$t('绑定监控')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.monitorIdList" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="deviceIdList" >
        <span slot="label" >
          <a-tooltip>
            {{$t('绑定设备')}}<template slot="title">{{$t('绑定设备')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceIdList" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>


      <a-form-model-item :label="this.$t('港口图片')" prop="picture">
        <a-upload
          name="picture"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.picture"
            :src="form.picture"
            alt="picture"
            style="height: 200px; width: 200px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{$t('通用.按钮.上传')}}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 60 x 60</span>
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('备注')}}<template slot="title">{{$t('备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>


      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPort, addPort, updatePort } from '@/api/gather/port/port'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        name: null,

        picture: null,

        positioningType: null,

        portType: null,

        remark: null,

        userId: null,

        monitorIdList: null,

        deviceIdList: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        picture: [
          { required: true, message: this.$t('港口图片')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        userId: [
          { required: true, message: this.$t('所属用户')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        userName: [
          { required: true, message: this.$t('所属用户')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        userPhone: [
          { required: true, message: this.$t('所属用户电话')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('港口名称')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        positioningType: [
          { required: true, message: this.$t('定位类型')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        portType: [
          { required: true, message: this.$t('物标')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        name: null,
        picture: null,
        positioningType: null,
        portType: null,
        remark: null,
        userId: null,
        monitorIdList: null,
        deviceIdList: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPort({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updatePort(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addPort(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      }else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'picture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },

  }
}
</script>
